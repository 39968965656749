const state = {
    oldReservationsToBeFinished: null,
    oldReservationsToBeFinishedCount: 0,
    oldReservationsToBeStarted: null,
    oldReservationsToBeStartedCount: 0,
    reservationsToBeFinished: null,
    reservationsToBeFinishedCount: 0,
    reservationsToBeStarted: null,
    reservationsToBeStartedCount: 0,
    reservations: null,
    totalBoxCount: 0,
    passcode: null
};

const getters = {
    oldReservationsToBeFinished: state => state.oldReservationsToBeFinished,
    oldReservationsToBeStarted: state => state.oldReservationsToBeStarted,
    reservationsToBeFinished: state => state.reservationsToBeFinished,
    reservationsToBeStarted: state => state.reservationsToBeStarted,
    oldReservationsToBeFinishedCount: state => state.oldReservationsToBeFinishedCount,
    oldReservationsToBeStartedCount: state => state.oldReservationsToBeStartedCount,
    reservationsToBeFinishedCount: state => state.reservationsToBeFinishedCount,
    reservationsToBeStartedCount: state => state.reservationsToBeStartedCount,
    reservations: state => state.reservations,
    totalBoxCount: state => state.totalBoxCount,
    passcode: state => state.passcode
};

const actions = {
    
};

const mutations = {
    setOldReservationsToBeFinished: (state, oldReservationsToBeFinished) => (state.oldReservationsToBeFinished = oldReservationsToBeFinished),
    setOldReservationsToBeStarted: (state, oldReservationsToBeStarted) => (state.oldReservationsToBeStarted = oldReservationsToBeStarted),
    setReservationsToBeFinished: (state, reservationsToBeFinished) => (state.reservationsToBeFinished = reservationsToBeFinished),
    setReservationsToBeStarted: (state, reservationsToBeStarted) => (state.reservationsToBeStarted = reservationsToBeStarted),
    setReservations: (state, reservations) => (state.reservations = reservations),
    setOldReservationsToBeFinishedCount: (state, oldReservationsToBeFinishedCount) => (state.oldReservationsToBeFinishedCount = oldReservationsToBeFinishedCount),
    setOldReservationsToBeStartedCount: (state, oldReservationsToBeStartedCount) => (state.oldReservationsToBeStartedCount = oldReservationsToBeStartedCount),
    setReservationsToBeFinishedCount: (state, reservationsToBeFinishedCount) => (state.reservationsToBeFinishedCount = reservationsToBeFinishedCount),
    setReservationsToBeStartedCount: (state, reservationsToBeStartedCount) => (state.reservationsToBeStartedCount = reservationsToBeStartedCount),
    setTotalBoxCount: (state, totalBoxCount) => (state.totalBoxCount = totalBoxCount),
    setPasscode: (state, passcode) => (state.passcode = passcode)
};

export default {
    state,
    getters,
    actions,
    mutations
};

