<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
        <div class="container-fluid">
            <a class="navbar-brand" href="#">
                <img src="../../assets/logo.png" width="40" height="40" alt="">
            </a>
            <MenuComponent class="w-full" ref="menu" id="overlay_menu" :model="items" :popup="true" />
            <div class="collapse navbar-collapse">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item active">
                        <a class="nav-link text-font" style="cursor: pointer" @click="users">Users</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-font" style="cursor: pointer" @click="reservations">Reservations</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-font" style="cursor: pointer" @click="settings">Settings</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-font" style="cursor: pointer" @click="logout">Sign out</a>
                    </li>
                </ul>
            </div>
            <button class="navbar-toggler" type="button" @click="toggle" aria-haspopup="true"
                aria-controls="overlay_menu">
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>
    </nav>
    <DialogComponent v-model:visible="setPriceVisible" modal header="Edit Profile" :style="{ width: '25rem' }">
        <span class="p-text-secondary block mb-5">Update your information.</span>
        <div class="flex align-items-center gap-3 mb-3">
            <label for="username" class="font-semibold w-6rem">Unit price</label>
            <InputText v-model="price" id="price" class="flex-auto" autocomplete="off" />
        </div>
        <div class="flex justify-content-end gap-2">
            <ButtonComponent class="rectangle-parent" type="button" label="Cancel" severity="secondary"
                @click="setPriceVisible = false"></ButtonComponent>
            <ButtonComponent class="image-5-parent" type="button" label="Save" outlined raised @click="updatePrice">
            </ButtonComponent>
        </div>
    </DialogComponent>
</template>
<script setup>
import { getAuth, signOut } from 'firebase/auth';
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
const router = useRouter()
const menu = ref();
const auth = getAuth()
let price = ref(5)
let setPriceVisible = ref(false)
/*
import { getAuth, signOut } from 'firebase/auth';
const auth = getAuth()
const logout = () => {
    signOut(auth).then(() => {
        console.log('user signed out manually');
    }).catch(error => {
        console.log('error signing out' + error);
    })
}*/
onMounted(() => {
    console.log("Navbar mounted");
})
const updatePrice = () => {
    console.log("Price updated");
}
const reservations = () => {
    router.push({
        name: "ReservationsPage"
    })
}
const users = () => {
    router.push({
        name: "UsersView"
    })
}
const settings = () => {
    router.push({
        name: "SettingsPage"
    })
}
const logout = () => {
    signOut(auth).then(() => {
        localStorage.setItem('isAuthenticated', false)
        router.push({
            name: "AuthenticationPage"
        })
    }).catch(error => {
        console.log('error signing out' + error);
    })
}
const home = () => {
    router.push({
        name: "HomePage"
    })
}

const items = ref([
    {
        separator: true
    },
    {
        label: 'Baggage Locker Admin App',
        items: [
            {
                label: 'Accueil',
                command: () => {
                    home()
                }
            },
            {
                label: 'Users',
                command: () => {
                    users()
                }
            },
            {
                label: 'Reservations',
                command: () => {
                    reservations()
                }
            },
            {
                label: 'Settings',
                command: () => {
                    settings()
                }
            },
            {
                label: 'Sign out',
                command: () => {
                    logout()
                }
            },
            {
                separator: true
            }
        ]
    }
]);
const toggle = (event) => {
    menu.value.toggle(event);
};

</script>
<style scoped></style>
