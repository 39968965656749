const state = {
    users: null
};

const getters = {
    users: state => state.users
};

const actions = {
    
};

const mutations = {
    setUsers: (state, users) => (state.users = users)
};

export default {
    state,
    getters,
    actions,
    mutations
};

