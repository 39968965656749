<template>
  <div class="container-fluid text-center mt-5 mb-5">
    <NavBar />
    <ToastComponent></ToastComponent>
    <p class="fw-bolder text-success margin-top-navbar">Users List</p>
    <div class="flex row">
      <div class="row">
        <div class="col-11"></div>
        <button @click="fetchUsers" type="button" class="btn btn-success float-right col-1 mb-2">
          Refresh
        </button>
      </div>
      <div class="col-1 fw-bold">
        <label>ID</label>
      </div>
      <div class="col-1 fw-bold">
        <label>Enabled
        </label>
      </div>
      <div class="col-2 fw-bold">
        <label>First Name</label>
      </div>
      <div class="col-2 fw-bold">
        <label>Last Name</label>
      </div>
      <div class="col-2 fw-bold">
        <label>Username</label>
      </div>
      <div class="col-2 fw-bold">
        <label>Email</label>
      </div>
      <div class="col-1 fw-bold">
        <label>Phone</label>
      </div>
      <div class="col-1 fw-bold">
        <label>Creation Date</label>
      </div>
    </div>
    <div v-if="notRefreshing">
      <div class="flex row" v-for="user in users" :key="user.id">
        <div class="col-1">
          <span class="wrap-text font-size-status">{{ user.id }}</span>
        </div>
        <div class="col-1">
          <span class="wrap-text font-size-status">{{ user.enabled }}</span>
        </div>
        <div class="col-2">
          <span class="wrap-text font-size-status">{{ user.firstName }}</span>
        </div>
        <div class="col-2">
          <span class="wrap-text font-size-status">{{ user.lastName }}</span>
        </div>
        <div class="col-2">
          <span class="wrap-text font-size-status">{{ user.username }}</span>
        </div>
        <div class="col-2">
          <span class="wrap-text font-size-dates">{{ user.email }}</span>
        </div>
        <div class="col-1">
          <span class="wrap-text font-size-status">{{ user.telephone }}</span>
        </div>
        <div class="col-1">
          <span class="wrap-text font-size-dates">{{ user.creationDate }}</span>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Refreshing...</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import UserService from '../services/UserService'
import { onMounted, ref } from 'vue';
import NavBar from '@/components/layouts/NavBar.vue';
import { useStore } from 'vuex';
import { useToast } from "primevue/usetoast";
const toast = useToast();
const store = useStore();
let notRefreshing = ref(true)
let users = ref(store.getters.users)
const fetchUsers = () => {
  notRefreshing.value = false
  UserService.getUsers().then((response) => {
    console.log("response="+response.data);
    notRefreshing.value = true
    if (response.data.errors) {
      toast.add({ severity: "error", summary: "Error", detail: "An error occurred. " + response.errors });
      return
    }
    store.commit('setUsers', response.data)
    users.value = response.data
  })
}
onMounted(() => {
  console.log(store.getters.users);
  //users.value = ref(store.getters.users)
})
</script>
<style scoped>
.font-size-dates {
  font-size: 12px;
}

.font-size-status {
  font-size: 14px;
}

.margin-top-navbar {
  margin-top: 70px;
  font-size: 40px;
}
</style>