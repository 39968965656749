<template>
    <div>
        <h1 class="mt-5"><b>ADMIN BAGAGE LOCKER</b></h1>
        <img src="../assets/logo.png" alt="logo" class="logo" />
        <ToastComponent></ToastComponent>
    </div>
    <div class="flex row text-center items-center">
        <div class="col-3"></div>
        <div class="col-6">
            <div class="col-12 row form-group mb-1">
                <div class="input-group" data-mdb-input-init>
                    <label for="email" class="form-label">Enter your credentials:</label>
                </div>
            </div>
            <div class="col-12 row form-group mb-4">
                <div class="input-group" data-mdb-input-init>
                    <input v-model="email" type="email" placeholder="Email"
                        class="form-control height" />
                </div>
            </div>
            <div class="col-12 row form-group mb-4">
                <div class="input-group" data-mdb-input-init>
                    <input v-model="password" type="password" placeholder="Password"
                        class="form-control height" />
                </div>
            </div>
            <button class="btn btn-primary login-button col-12 font-bold" @click="login">Se connecter</button>
        </div>
        <div class="col-3"></div>

    </div>
</template>
<script setup>
import { ref } from 'vue'
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth'
import { useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';
//import { useStore } from 'vuex';
const auth = getAuth()
let password = ref(null)
const router = useRouter()
let email = ref(null)
//const store = useStore()
const toast = useToast()
const login = () => {
    signInWithEmailAndPassword(auth, email.value, password.value).then(response => {
        console.log(response.data);
        localStorage.setItem('isAuthenticated', true)
        router.push({ name: 'UsersView' })
    }).catch(error => {
        console.log(error.code);
        const errorCode = error.code;
        if (errorCode === "auth/invalid-credential") {
            toast.add({ severity: 'error', summary: 'Wrong credentials', detail: 'Your email or password is wrong!', life: 3500 });
        } else {
            toast.add({ severity: 'error', summary: 'Error', detail: errorCode, life: 3500 });
        }
    })
}
onAuthStateChanged(auth, (user) => {
    console.log("User state changed");
    if (user) {
        console.log(user);//store.commit('login', user)
    } else {
        //store.commit('logout')
        router.push({ name: 'AuthenticationPage' })
    }
});
</script>
<style scoped></style>