import axios from 'axios';
class UserService {
    
  //baseURL = 'https://bagagelocker-api.onrender.com/api/v1/admin';
  baseURL = 'https://bagagelocker-cancel-order.onrender.com/api/v1/admin';
  async getUsers() {
      return await axios.get(this.baseURL + '/setting/getUsers');
  }
}

export default new UserService();