import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate"
import reservations from "./modules/reservations";
import users from "./modules/users";
const store = createStore({
    modules: {
        reservations,
        users
    },
    plugins: [createPersistedState()]
})
export default store