import { createWebHistory, createRouter } from "vue-router";
//import Boxes from '../views/Boxes.vue'
import UsersView from '../views/UsersView.vue'
import ReservationsPage from '../views/ReservationsPage.vue'
//import Doors from '../views/Doors.vue'
import SettingsPage from '../views/SettingsPage.vue'
//import TestPage from '../views/TestPage.vue'
import AuthenticationPage from "@/views/AuthenticationPage.vue";
const routes = [
  { path: '/', component: UsersView, name: "HomePage" },
  { path: '/users', component: UsersView, name: "UsersView" },
  { path: '/reservations', component: ReservationsPage, name: "ReservationsPage" },
  { path: '/config', component: SettingsPage, name: "SettingsPage" },
  { path: '/authenticate', component: AuthenticationPage, name: "AuthenticationPage" }
]

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
})
router.beforeEach((to, from, next) => {
  const publicPages = ['/authenticate']; // Add other public pages if any
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('isAuthenticated');
  if (authRequired && !loggedIn) {
    return next('/authenticate');
  }

  next();
});

export default router 