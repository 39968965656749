//import { inject } from "vue";
import axios from 'axios';

class SettingsService {
    //baseURL = 'http://localhost:8080/api/v1/admin';
    //baseURL = 'https://bagagelocker-api.onrender.com/api/v1/admin';
    baseURL = 'https://bagagelocker-cancel-order.onrender.com/api/v1/admin';
    async setOldReservationsFinished() {
        return await axios.get(this.baseURL + '/setting/setOldReservationsFinished');
    }
    async createFakeReservation(duration) {
        return await axios.get(this.baseURL + '/setting/createFakeReservation/' + duration);
    }
    async getOldReservationsToBeFinished() {
        return await axios.get(this.baseURL + '/setting/getOldReservationsToBeFinished');
    }
    async setOldReservationsOngoing() {
        return await axios.get(this.baseURL + '/setting/setOldReservationsOngoing');
    }
    async getOldReservationsToBeStarted() {
        return await axios.get(this.baseURL + '/setting/getOldReservationsToBeStarted');
    }
    async setReservationsOngoing() {
        return await axios.get(this.baseURL + '/setting/setReservationsOngoing');
    }

    async getReservationsToBeStarted() {
        return await axios.get(this.baseURL + '/setting/getReservationsToBeStarted');
    }

    async setReservationsFinished() {
        return await axios.get(this.baseURL + '/setting/setReservationsFinished');
    }

    async getReservationsToBeFinished() {
        return await axios.get(this.baseURL + '/setting/getReservationsToBeFinished');
    }
    async getTotalBoxCount() {
        return await axios.get(this.baseURL + '/setting/getTotalBoxCount')
    }
    async setTotalBoxCount(totalBoxCount) {
        return await axios.post(this.baseURL + '/setting/setTotalBoxCount', {"totalBoxCount": totalBoxCount})
    }
    async getPasscode() {
        return await axios.get(this.baseURL + '/setting/getPasscode')
    }
    async setPasscode(passcode) {
        return await axios.post(this.baseURL + '/setting/setPasscode', {"passcode": passcode})
    }
    async sendEmailTest(email) {
        return await axios.get(this.baseURL + '/setting/sendEmailTest/' + email)
    }
    async sendReservationConfirmedEmailTest(email) {
        return await axios.get(this.baseURL + '/setting/sendReservationConfirmedTest/' + email)
    }
    async sendReservationFinishedEmailTest(email) {
        return await axios.get(this.baseURL + '/setting/sendReservationFinishedTest/' + email)
    }
    async activateOrderCancellation() {
        return await axios.get(this.baseURL + '/setting/activateOrderCancellation')
    }
    async deactivateOrderCancellation() {
        return await axios.get(this.baseURL + '/setting/deactivateOrderCancellation')
    }
    async getOrderCancellation() {
        return await axios.get(this.baseURL + '/setting/getOrderCancellation')
    }
}
export default new SettingsService();
